.consent {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
}

.errorHandling {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	&__content {
		background-color: var(--neutralBackground);
		padding: 16px;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
