.lightRepGen {
	height: 100vh;
	width: 100vw;
	background-image: url("./images/Background.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

body {
	margin: 0;
	height: 100%;
}

.main {
	display: flex;
	height: calc(100% - 56px);
}
